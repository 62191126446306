import * as React from "react"
import SiteContainer from "../../../components/site-container";
import {Helmet} from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import analytics from "../../../images/vectors/undraw_Business_analytics_64el.svg"
import measure_realtime from '../../../images/vectors/undraw_online_connection_6778.svg'
import server_cluster from '../../../images/vectors/undraw_detailed_analysis_re_tk6j.svg'
import PricingSection from "../../../components/PricingSection";
import RedshiftFacebookConversionsWorkflow from "../../../components/workflows/RedshiftFacebookConversionsWorkflow";

const IndexPage = () => {
    return (
        <SiteContainer>
            <Helmet>
                <title>Firstparty - Attribution that Increases Conversions</title>
                <meta name="description" content="Know every channel, source, pageview, click, and event that resulted in a purchase, signup, or closed-won
                                deal." />
                <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>" />
            </Helmet>

            <nav className="bg-gray-200">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb breadcrumb-scroll">
                                <li className="breadcrumb-item">
                                    <span className="text-gray-700">
                                        Solutions
                                    </span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Attribution
                                </li>
                            </ol>

                        </div>
                    </div>
                </div>
            </nav>

            <section className="py-8 py-md-11">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={10} className="text-center">
                            <h1 className="display-1 fw-bold aos-init aos-animate" data-aos="fade-up"
                                data-aos-delay="50">Increase campaign conversions
                            </h1>
                            <p className="lead text-muted mb-6 aos-init aos-animate" data-aos="fade-up"
                               data-aos-delay="100">
                                Know every channel, source, pageview, click, and event that resulted in a purchase, signup, or closed-won
                                deal. Identify exactly which marketing efforts result in not only captured emails, but also connected
                                phone calls, pipeline generation, and revenue.
                            </p>
                            <p className="mb-7 mb-md-9 aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
                                <a className="btn btn-primary shadow lift" href="https://app.firstpartyhq.com/authentication/signup">
                                    Get Started for Free<i className="fe fe-arrow-right ms-2" />
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-8 py-md-14 bg-gray-800">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 mb-5 align-self-center">
                            <h3 className="text-gray-100">Get Started with a Workflow</h3>
                            <p className="text-gray-400">
                                Workflows guide you through configuring multiple Firstparty components to help achieve
                                an objective, and can help you solve marketing problems like analytics, attribution,
                                and audience creation in just a few clicks.
                            </p>
                            <a className="fw-bold text-white text-decoration-none" href="/workflows/">Explore All Workflows <i className="fe fe-arrow-right ms-1"></i></a>
                        </div>
                        <div className="col-12 col-md-8 mb-5">
                            <RedshiftFacebookConversionsWorkflow />
                        </div>
                    </div>
                </div>
            </section>

            <div className="section bg-secondary pt-10 pt-md-10 pb-8 pb-md-11">
                <div className="container">
                    <div className="row justify-content-between align-items-center mb-10">
                        <div className="col order-md-2 text-center">

                            <img className="img-fluid w-75 w-md-100 mb-6 mb-md-0"
                                 src={analytics} alt="..." />

                        </div>
                        <div className="col-12 col-md-7 order-md-1">

                            <h2 className="text-white">
                                Optimize campaigns for the business objectives you actually care about
                            </h2>

                            <div className="lead text-light mb-0">
                                See the exact web pages that an individual viewed prior to becoming an opportunity.
                                See which digital channels or sources resulted in the most closed-won deals. Measure
                                the total pipeline return of a specific tagged ad campaign. Look at the aggregate, or
                                the specific - down to each individual record and interaction.
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">

                                    <div className="row gx-0 align-items-center mb-md-5">
                                        <div className="col-auto">

                                            <a className="btn btn-sm btn-rounded-circle btn-gray-400-10 disabled opacity-1"
                                               href="#!">
                                                <span>1</span>
                                            </a>

                                        </div>
                                        <div className="col">

                                            <hr className="d-none d-md-block bg-gray-900-50 me-n7" />

                                        </div>
                                    </div>

                                </div>
                                <div className="col col-md-12 ms-n5 ms-md-0">

                                    <h3 className="text-white">
                                        Collect Offline Data
                                    </h3>

                                    <p className="text-light mb-6 mb-md-0">
                                        Import first-party data from your existing warehouse, and augment that with Firstparty's
                                        Analytics solution
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">

                                    <div className="row gx-0 align-items-center mb-md-5">
                                        <div className="col-auto">

                                            <a href="#!"
                                               className="btn btn-sm btn-rounded-circle btn-gray-400-10 disabled opacity-1">
                                                <span>2</span>
                                            </a>

                                        </div>
                                        <div className="col">

                                            <hr className="d-none d-md-block bg-gray-900-50 me-n7" />

                                        </div>
                                    </div>

                                </div>
                                <div className="col col-md-12 ms-n5 ms-md-0">

                                    <h3 className="text-white">
                                        Send Offline Conversions to Ad Networks & Beyond
                                    </h3>

                                    <p className="text-light mb-6 mb-md-0">
                                        Automatically send offline conversions like CRM Opportunities, Subscription Renewals,
                                        and server-side activities to Meta Ads as conversion events
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">
                                    <div className="row gx-0 align-items-center mb-md-5">
                                        <div className="col-auto">
                                            <a href="#!"
                                               className="btn btn-sm btn-rounded-circle btn-gray-400-10 disabled opacity-1">
                                                <span>3</span>
                                            </a>

                                        </div>
                                    </div>

                                </div>
                                <div className="col col-md-12 ms-n5 ms-md-0">
                                    <h3 className="text-white">
                                        Optimize Campaigns for High-Value Conversions
                                    </h3>
                                    <p className="text-light mb-0">
                                        Use the high-quality data sent to your ad network from Firstparty to optimize
                                        your campaigns for the conversion goal you actually care about.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="pt-10 pt-md-15 pb-10 pb-md-14 bg-light">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-lg-10 text-center">
                            <h6 className="text-uppercase text-primary">
                                Analytics
                            </h6>
                            <h2>Measure Attributed Conversions with Firstparty Analytics</h2>
                            <p className="fs-lg text-gray-700 pb-5">
                                Firstparty collects, processes, stores, and lets you query web analytics data in realtime. All data can be stored on your infrastructure, and everything can be served from your domain.
                            </p>
                            <a href="/solutions/analytics/" className="btn btn-primary-soft">Explore Analytics</a>
                        </div>
                    </div>
                </div>
            </section>

            <div className="position-relative">
                <div className="shape shape-top shape-fluid-x shape-flip-x text-light">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h2880v125h-720L720 250H0V0z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="pb-8 pb-md-11">
                <Container>
                    <Row className="justify-content-between align-items-center">
                        <Col md={6} lg={5} className="order-md-2">
                            <div className="d-flex flex-column w-md-130 mb-6 mb-md-0">
                                <img src={measure_realtime} />
                            </div>
                        </Col>
                        <Col md={6} className="order-md-1 pt-md-12">
                            <h6 className="text-uppercase text-primary">
                                Increase Conversions
                            </h6>
                            <h2>
                                Connect the dots between web analytics and the CRM
                            </h2>
                            <p className="fs-lg text-gray-700 mb-6">
                                Firstparty ingests data from the CRM, including leads, contacts, opportunities, or any
                                other object, and makes them available to do everything from triggering
                                conversions to querying in analytics. Match any CRM object with known profiles in the
                                Firstparty CDP based on just about any match key you can think of.
                            </p>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p className="text-primary">
                                            Simple DNS setup
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p className="text-primary mb-lg-0">
                                            Cookies stay valid
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex">
                                        <span className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                        <i className="fe fe-check"></i>
                                    </span>
                                        <p className="text-primary">
                                            Mitigate ad blockers
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft me-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p className="text-primary mb-0">
                                            Supports all browsers
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-8 py-md-11">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-md-6 img-skewed img-skewed-end">
                            <div className="card rounded-lg bg-black mb-6 mb-md-0 img-skewed-item screenshot">
                                <div className="card-header border-white-10">
                                    <div className="d-flex">
                                        <span className="bg-danger rounded-circle"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-warning rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-success rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <code className="highlight hljs"
                                          data-typed="{&quot;backSpeed&quot;:2, &quot;strings&quot;: [&quot;>&nbsp;$&nbsp;npm&nbsp;install<br/><span class=\&quot;text-success\&quot;>Everything&nbsp;is&nbsp;installed</span><br/><br/>>&nbsp;$&nbsp;npm start<br/><span class=\&quot;text-success\&quot;>scss&nbsp;watching</span><br/><span class=\&quot;text-success\&quot;>LiveReload&nbsp;started</span><br/><span class=\&quot;text-success\&quot;>Opening&nbsp;localhost:8080</span><br/><br/>>&nbsp;$&nbsp;that’s&nbsp;it<br/><span class=\&quot;text-success\&quot;>Yup,&nbsp;that’s&nbsp;it.</span>&quot;]}"
                                          style={{minHeight: "183px"}}>
                                        SELECT
                                        <div className="px-3">
                                            contacts.email, <br />contacts.facebook_click_id, <br />opportunities.value
                                        </div>
                                        <br />
                                        FROM
                                        <div className="px-3">contacts</div>
                                        <br />
                                        JOIN
                                        <div className="px-3">opportunities</div>
                                        ON
                                        <div className="px-3">
                                            opportunities.account_id = contacts.account_id
                                        </div>
                                    </code>
                                    <span className="typed-cursor" aria-hidden="true">|</span>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 offset-md-1">
                            <h6 className="text-uppercase text-primary">
                                Increase Conversions
                            </h6>
                            <h2>Turn Database Queries Into Facebook Ads Conversions</h2>
                            <p className="fs-lg text-gray-700">
                                Firstparty will create an Event for every row of data your query returns from your
                                warehouse. These Events can be used to trigger a Conversion of your choice in Facebook
                                Ads, which may be set as a conversion goal in any Campaign or Ad Set.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-8 py-md-11">
                <Container>
                    <Row className="justify-content-between align-items-top">
                        <Col md={6} lg={5} className="order-md-2">
                            <div className="d-flex flex-column w-md-130 mb-6 mb-md-0">
                                <img src={server_cluster} />
                            </div>
                        </Col>
                        <Col md={6} className="order-md-1">
                            <h6 className="text-uppercase text-primary">
                                Measure Campaign Effectiveness
                            </h6>
                            <h2>
                                Know every interaction that resulted in a won Opportunity
                            </h2>
                            <p className="fs-lg text-gray-700 mb-6">
                                See the exact web pages that an individual viewed prior to becoming an opportunity. See
                                which digital channels or sources resulted in the most closed-won deals. Measure the
                                total pipeline return of a specific tagged ad campaign. Look at the aggregate, or the
                                specific - down to each individual record and interaction.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <PricingSection />

        </SiteContainer>
    )
}

export default IndexPage
